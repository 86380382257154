// Use Marengo Bootstrap Theme
$image-path: '../node_modules/@simpl/marengo-bootstrap/dist/images/';
$input-border-radius: 0.5em !default;
@import '@simpl/marengo-bootstrap/src/variants/simpl-marengo-bootstrap';

// Import component styles for SiMPL Marengo components
@import '@simpl/marengo-ng/simpl-marengo-ng';

// Import Marengo icons
@import '@simpl/marengo-icons/dist/style/simpl-marengo-icons.scss'; // sass-lint:disable-line clean-import-paths

@import './assets/fonts/iqpinpoint-web-icons/styles.css';


main {
    overflow: hidden;
    overflow-y: auto;
}
.modal-md {
    width: 70% !important;
}
.isChild{
    margin-left: 40px !important;
}
.isChildValue{
    margin-left: -40px !important;
}
.cc-window.cc-floating {
    max-width: 57em !important;
}
