@charset "UTF-8";

@font-face {
    font-family: 'iqpinpoint-web-icons';
    src: url('fonts/iqpinpoint-web-icons.eot');
    src: url('fonts/iqpinpoint-web-icons.eot?#iefix') format('embedded-opentype'),
        url('fonts/iqpinpoint-web-icons.woff') format('woff'), url('fonts/iqpinpoint-web-icons.ttf') format('truetype'),
        url('fonts/iqpinpoint-web-icons.svg#iqpinpoint-web-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[data-icon]:before {
    font-family: 'iqpinpoint-web-icons' !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^='management-']:before,
[class*=' management-']:before {
    font-family: 'iqpinpoint-web-icons' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.management-projectfile-menu-settings-outline:before {
    content: '\61';
}
.management-projectfile-menu-security-outline:before {
    content: '\62';
}
.management-menu-clock:before {
    content: '\63';
}
.management-menu-clock-outline:before {
    content: '\64';
}
.management-menu-document:before {
    content: '\65';
}
.management-menu-document-outline:before {
    content: '\66';
}
.management-menu-home:before {
    content: '\67';
}
.management-menu-home-outline:before {
    content: '\68';
}
.management-menu-security:before {
    content: '\69';
}
.management-menu-security-outline:before {
    content: '\6a';
}
.management-menu-settings:before {
    content: '\6b';
}
.management-menu-settings-outline:before {
    content: '\6c';
}
.management-projectfile-menu-clock-outline:before {
    content: '\6d';
}
.management-projectfile-menu-document-outline:before {
    content: '\6e';
}
.management-projectfile-menu-home-outline:before {
    content: '\6f';
}
